<script lang="ts" setup>
const props = defineProps({
  backUrl: {
    type: String,
  },
});

const hasHistory = computed(() => {
  if (process.server) return false;
  return window.history.state.position > 0;
});

const pseudoLoginStore = usePseudoLogin();
const { isPseduLogin, pseudoId } = storeToRefs(pseudoLoginStore);

const onNavigateBack = () => {
  if (props.backUrl) {
    navigateTo(props.backUrl, { replace: true });
  } else {
    useRouter().back();
  }
};
</script>

<template>
  <div class="h-screen">
    <BaseAppBar class="fixed">
      <template #leading>
        <div class="h-full flex flex-row items-center">
          <slot name="app-bar-leading">
            <ClientOnly>
              <BaseIconButton
                v-if="hasHistory || backUrl"
                :size="40"
                @click="() => onNavigateBack()"
              >
                <icon name="material-symbols:arrow-back" size="20" />
              </BaseIconButton>
            </ClientOnly>
          </slot>
          <div class="ml-2 text-h5">
            <slot name="app-bar-title" />
          </div>
        </div>
      </template>
      <template #actions>
        <span v-if="isPseduLogin" class="flex flex-row items-center mr-2">
          <span class="text-danger-default mr-2">Pseudoed</span>
          <BaseTextButton @click="pseudoLoginStore.setPseudoId(undefined)">
            Clear
          </BaseTextButton>
        </span>
        <HelpButton class="mr-2" />
        <ReportProblemButton :is-consolidated="true" />
        <slot name="app-bar-actions" />
      </template>
    </BaseAppBar>
    <div class="pt-[60px] h-full">
      <div class="p-6 h-full">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
